.messenger {
  width: 100%;
  height: 100vh;
  background-color: white;

  .row {
    height: 100%;

    .col-9 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #f4f8fc;

      .offer-to-add-friend {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: white;
        border-radius: 20px;
        padding: 10px;
        margin: 20px;
        width: 385px;
        height: 180px;

        .add-friend {
          padding: 10px;

          p {
            font-size: 16px;
            font-weight: 600;
            color: #333;
            text-align: center;
            margin: 0;
            padding: 10px;
            border-radius: 5px;
            margin-bottom: 10px;
          }

          .invite {
            padding: 20px;
            border-radius: 100px;
            background-color: #c2dcf1;

            a {
              display: inline-block;
              font-size: 16px;
              text-align: center;
              color: #143d64;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// Responsive Styles
// Mobile (0px - 844px)
@media (max-width: 844px) {
  .messenger {
    height: 100vh; // Allow height to adjust based on content

    .row {
      flex-direction: column; // Stack columns vertically on mobile

      .col-9 {
        width: 100%; // Full width on mobile
        padding: 5px; // Add padding for better spacing
        //max-height: 80%; // Allow height to adjust based on content
        //overflow: visible;
        box-sizing: border-box;
        height: 100vh;
        .offer-to-add-friend {
          width: 100%; // Full width on mobile
          height: auto; // Allow height to adjust
          margin: 10px 0; // Adjust margin for better spacing

          .add-friend {
            p {
              font-size: 14px; // Smaller font size for mobile
            }

            .invite {
              padding: 15px; // Smaller padding for mobile

              a {
                font-size: 14px; // Smaller font size for mobile
              }
            }
          }
        }
      }
    }
  }
}

// Tablet (768px - 1023px)
@media (min-width: 768px) and (max-width: 1023px) {
  .messenger {
    .row {
      .col-9 {
        width: 80%; // Slightly smaller width for tablets

        .offer-to-add-friend {
          width: 350px; // Adjust width for tablets
          height: 160px; // Adjust height for tablets

          .add-friend {
            p {
              font-size: 15px; // Slightly smaller font size for tablets
            }

            .invite {
              padding: 18px; // Slightly smaller padding for tablets

              a {
                font-size: 15px; // Slightly smaller font size for tablets
              }
            }
          }
        }
      }
    }
  }
}

// Desktop (1024px and above)
@media (min-width: 1024px) {
  .messenger {
    .row {
      .col-9 {
        width: 70%; // Adjust width for desktop

        .offer-to-add-friend {
          width: 385px; // Original width for desktop
          height: 180px; // Original height for desktop

          .add-friend {
            p {
              font-size: 16px; // Original font size for desktop
            }

            .invite {
              padding: 20px; // Original padding for desktop

              a {
                font-size: 16px; // Original font size for desktop
              }
            }
          }
        }
      }
    }
  }
}
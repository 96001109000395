.col-3 {
    width: 30%;

    .left-side {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      justify-content: start;
      background-color: #ffffff;

      .top {
        padding-top: 20px;
        display: flex;

        .icons {
          position: relative;

          .image-name {
            width: 40px;
            height: 40px;
            margin-left: 15px;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
  
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
  
          .theme_logout.show {
            transform: scale(1);
          }
  
          .theme_logout {
            box-shadow: 0px 4px 16.5px #00000040;
            width: 200px;
            height: 370%;
            background-color: white;
            transform: scale(0);
            position: absolute;
            transition: all 0.6s;
            top: 100%;
            padding: 10px;
            color: #143d64;
            z-index: 1;
            border-radius: 10px;
  
            .profile {
              display: flex;
              align-items: center;
              padding: 10px;
              cursor: pointer;
  
              .MdOutlinePerson {
                font-size: 50px;
                color: #017aff;
                background-color: #ffffff;
              }
  
              h3 {
                padding-left: 25px;
                font-size: 20px;
                font-weight: 600;
              }
            }
  
            .dark-mode {
              display: flex;
              align-items: center;
              padding: 10px;
              cursor: pointer;
  
              i {
                margin-right: 25px;
              }
            }
  
            .logout {
              display: flex;
              align-items: center;
              padding: 10px;
              cursor: pointer;
  
              .FaSignOutAlt {
                font-size: 20px;
                color: #017aff;
                background-color: #ffffff;
              }
  
              h3 {
                padding-left: 25px;
                font-size: 20px;
                font-weight: 600;
              }
            }
          }
        }
      }
  
      .friend-search {
        display: flex;
        position: relative;
        padding: 0px 15px;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 20px;
  
        .add-friend-icon {
          display: flex;
          border-radius: 50%;
          background-color: #f0f0f0;
          padding: 10px;
          cursor: pointer;
        }
  
        .search {
          display: flex;
          width: calc(100% - 35px);
          height: 40px;
          background-color: #f0f0f0;
          border-radius: 22px;
          border: none;
          margin-left: 5px;
          padding: 10px 20px;
          align-items: center;
          justify-content: space-between;
  
          svg {
            color: #707991;
          }
  
          button {
            border-radius: 50px;
            border: none;
            color: var(--text-color);
            font-size: 20px;
            background-color: transparent;
          }
  
          .form-control {
            width: 100%;
            border-bottom-right-radius: 50px;
            border-top-right-radius: 50px;
            border: none;
            color: var(--text-color);
            background-color: #f0f0f0;
            font-size: 15px;
            outline: none;
            margin-left: 10px;
  
            &::placeholder {
              color: var(--text-color);
              opacity: 0.5;
            }
          }
        }
  
        .add-friend {
          width: calc(20%);
          height: calc(50%);
          padding-left: 5%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
  
          i {
            font-size: 42px;
            color: #143d64;
            border-radius: 50%;
            background-color: #b9d6fa;
          }
        }
      }
    }
  }
  
  // Responsive Styles
  // Mobile (0px - 767px)
  @media (max-width: 767px) {
    .col-3 {
      width: 100%; // Full width on mobile
  
      .left-side {
        .top {
          padding-top: 10px; // Reduce padding for mobile
  
          .icons {
            .image-name {
              width: 35px; // Smaller image size for mobile
              height: 35px;
              margin-left: 10px; // Adjust margin for mobile
            }
  
            .theme_logout {
              width: 180px; // Adjust width for mobile
              height: 420%; // Adjust height for mobile
              font-size: 14px; // Smaller font size for mobile
  
              .profile h3,
              .logout h3 {
                font-size: 16px; // Smaller font size for mobile
              }
            }
          }
        }
  
        .friend-search {
          flex-direction:row;
          padding: 0px 10px; // Adjust padding for mobile
          align-items: center;
  
          .search {
            width: 80%; // Full width on mobile
            margin-left: 0; // Remove margin for mobile
          }
  
          .add-friend {
            width: 20%; // Full width on mobile
            justify-content: flex-start; // Align to the left
            //margin-top: 10px; // Add margin for spacing
          }
        }
      }
    }
  }
  
  // Tablet (768px - 1023px)
  @media (min-width: 768px) and (max-width: 1023px) {
    .col-3 {
      width: 25%; // Adjust width for tablets
  
      .left-side {
        .top {
          .icons {
            .theme_logout {
              width: 180px; // Adjust width for tablets
              height: 320%; // Adjust height for tablets
            }
          }
        }
  
        .friend-search {
          .search {
            width: calc(100% - 30px); // Adjust width for tablets
          }
  
          .add-friend {
            width: calc(25%); // Adjust width for tablets
          }
        }
      }
    }
  }
  
  // Desktop (1024px and above)
  @media (min-width: 1024px) {
    .col-3 {
      width: 30%; // Original width for desktop
  
      .left-side {
        .top {
          .icons {
            .theme_logout {
              width: 200px; // Original width for desktop
              height: 370%; // Original height for desktop
            }
          }
        }
  
        .friend-search {
          .search {
            width: calc(100% - 35px); // Original width for desktop
          }
  
          .add-friend {
            width: calc(20%); // Original width for desktop
          }
        }
      }
    }
  }
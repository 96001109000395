.col-9 {
  .right-side {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    input[type='checkbox'] {
      display: none;
    }

    .row {
      height: 100%;
      width: 100%;

      .col-8 {
        transition: all 0.5s;
        position: relative;
        width: 100%;
        height: 99vh;

        .message-send-show {
          height: 100%;
          border-right: 1px solid var(--border-color);
          margin-top: 1px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 20px 10px;

            .image-name {
              display: flex;
              align-items: center;
              cursor: pointer;

              .image {
                position: relative;
                width: 40px;
                height: 40px;

                img {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                }

                .active-icon {
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background-color: rgb(28, 212, 28);
                  right: 0;
                  bottom: 0;
                }
              }

              .name-date {
                span {
                  font-size: 12px;
                  color: var(--text-color);
                  margin-left: 10px;
                }
                h3 {
                  padding-left: 10px;
                  color: var(--text-color);
                }
              }
            }

            .icons {
              display: flex;
              justify-content: space-between;

              i {
                height: 35px;
                width: 35px;
                margin-left: 8px;
                border-radius: 24px;
                color: #143D64;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}

// Responsive styles
@media (max-width: 768px) {
  .col-9 {
    .right-side {
      .row {
        .col-8 {
          .message-send-show {
            .header {
              flex-direction: column;
              align-items: flex-start;

              .image-name {
                .name-date {
                  h3 {
                    font-size: 16px;
                  }
                  span {
                    font-size: 10px;
                  }
                }
              }

              .icons {
                //margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .col-9 {
    .right-side {
      .row {
        .col-8 {
          .message-send-show {
            .header {
              padding: 10px 5px;
              flex-direction: row;
              align-items: center;
              .image-name {
                .image {
                  width: 30px;
                  height: 30px;

                  img {
                    width: 30px;
                    height: 30px;
                  }

                  .active-icon {
                    width: 8px;
                    height: 8px;
                  }
                }

                .name-date {
                  h3 {
                    font-size: 14px;
                  }
                  span {
                    font-size: 8px;
                  }
                }
              }

              .icons {
                i {
                  height: 30px;
                  width: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}